<template>
    <client-page>
        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="Contact"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>

        <section class="sub-section pb-28 pb-md-28 pb-lg-54 greeting-bg">
            <v-container>
                <h3 class="sub-tit--lg" data-aos="fade-up">
                    <span class="color-point">F1 Security</span> 를 오시는 길 입니다
                </h3>
            </v-container>
        </section>

        <section class="pt-0 pt-md-0 pt-lg-0 sub-section--last bg-grey">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.354085087934!2d126.88393671564631!3d37.47596983706655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b61e13c9bce95%3A0x236b85f21ee492f2!2z7ISc7Jq47Yq567OE7IucIOq4iOyynOq1rCDrsprqvYProZwgMjM0!5e0!3m2!1sko!2skr!4v1663061303355!5m2!1sko!2skr" width="100%" height="540" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <v-container>
                <div class="radius-xl bg-white border pa-30 pa-md-40 px-lg-60 px-lg-100 mt-n100 info-box" data-aos="fade">
                    <h4 class="font-size-20 font-size-lg-28 line-height-1 font-weight-bold color-point mb-4 mb-lg-10">
                        F1 Security
                    </h4>
                    <h5 class="sub-tit--sm font-weight-bold mb-16 mb-lg-26">
                        08513 서울 금천구 벚꽃로 234 에이스하이엔드타워6차 1402호
                    </h5>
                    <div class="d-flex flex-column flex-md-row align-md-center">
                        <p class="page-text">
                            <span class="color-gray d-inline-block mr-6 mr-lg-12">전화번호</span>070-4640-3030
                        </p>
                        <span class="line d-none d-md-block mx-10 mx-lg-20"></span>
                        <p class="page-text">
                            <span class="color-gray d-inline-block mr-6 mr-lg-12">이메일</span>sales@f1security.co.kr
                        </p>
                    </div>
                </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.info-box{
    position: relative;
    background-position: right 16px bottom;
    background-size:  30% auto;
    background-repeat: no-repeat;
    background-image: url(/images/sub/about/contact/cantact-bg.png);
}
.line{
    width: 1px;
    height: 12px;
    background-color: var( --border-color);
}

@media (min-width:576px){
}
@media (min-width:768px){
    .info-box{
    background-position: right 30px bottom;
    background-size:  20% auto;
}
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>